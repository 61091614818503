@import "../../global.scss";

.a {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fdebd3;

  @include mobile {
    flex-direction: column;
    text-align: center;
  }

  a {
    position: absolute;
    bottom: 10px;
    right: 28%;

    @include mobile {
      right: 48%;
    }

    img {
      width: 40px;
      animation: arrowBlink 2s infinite;

      @include mobile {
        display: none;
      }
    }

    @keyframes arrowBlink {
      100% {
        opacity: 0;
      }
    }
  }
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  @include mobile {
    width: 100%;
  }
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #95adbe;

  @include mobile {
    display: none;
  }
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;

  @include mobile {
    height: 30vh;
  }
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include mobile {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.a-award-uni {
  width: 110px;
  height: 55px;
  border-radius: 20px;
}

.a-title {
  font-size: 45px;
  font-weight: 500;
  color: #264e70;

  @include mobile {
    font-size: 25px;
  }
}

.a-sub {
  width: 90%;
  color: #679186;
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0px;

  @include mobile {
    font-size: 17px;
    margin: 5px 0px;
    text-align: center;
  }
}

.a-desc {
  width: 90%;
  font-weight: 400;
  font-size: 17px;
  color: #486662;
  margin-right: 20;

  @include mobile {
    display: none;
  }
}
.a-uni {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  flex: 0.9;

  @include mobile {
    flex-direction: column;
  }
}

.a-uni-texts {
  width: 70%;
  font-size: 17px;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 40px;

  @include mobile {
    width: 80%;
    margin-right: 0px;
    margin-left: 8px;
    font-size: 13px;
  }
}

.a-uni-title {
  color: #264e70;
  margin-bottom: 10px;

  @include mobile {
    font-size: 16px;
  }
}

.a-uni-desc {
  color: #486662;

  @include mobile {
    display: none;
  }
}

.a-right {
  flex: 1.2;
  @include mobile {
    padding: 20px;
  }
}

.button{
  margin-bottom: 20px;
  margin-top: 10px;
}
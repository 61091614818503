@import "../../global.scss";

.intro {
  background-color: #fdebd3;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;
    position: relative;

    @include mobile {
      flex: 0.5;
      overflow: hidden;
    }

    .imgContainer {
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
      background-color: #95adbe;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      align-items: flex-end;
      justify-content: left;
      float: right;
      display: flex;

      @include mobile {
        width: 600px;
        height: 700px;
        position: relative;
        justify-content: center;
        align-items: flex-start;
      }

      img {
        height: 90%;

        @include mobile {
          height: 50%;
        }
      }
    }
  }

  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 85%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      @include laptop {
        width: 80%;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;
        color: #264e70;

        @include mobile {
          text-align: center;
          font-size: 30px;
        }
      }

      h2 {
        color: #679186;
        font-size: 35px;
        @include mobile {
          text-align: center;
          font-size: 25px;
        }
      }

      h3 {
        color: #679186;
        font-size: 30px;

        @include mobile {
          text-align: center;
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: #264e70;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }

    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      @include mobile {
        display: none;
      }

      img {
        width: 40px;
        animation: arrowBlink 2s infinite;

        @include mobile {
          width: 30px;
        }
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}

@import "../../global.scss";

.c {
  height: 100vh;
  position: relative;
  background-color: #fdebd3;
}

.c-bg {
  width: 20px;
  height: 100%;
  background-color: $mainColor;
  position: absolute;
}

.c-wrapper {
  padding: 50px;
  display: flex;

  @include mobile {
    flex-direction: column;
    padding: 0px 50px;
  }
}

.c-left {
  position: relative;
  margin-top: 40px;
  flex: 1;

  @include mobile {
    margin-top: 0px;
  }
}

.c-right {
  margin-top: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @include mobile {
    margin-top: 0px;
  }
}

.c-title {
  font-size: 60px;
  width: 90%;
  color: $mainColor;

  @include mobile {
    font-size: 30px;
  }
}

.c-info-item {
 
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-weight: 500;
  width: 70%;
  color: #679186;
  

  @include mobile {
    margin: 20px 0px;
    width: 100%;
  }
}

.c-info-link {
 
  display: flex;
  align-items: flex-start;
  margin: 50px 0px;
  font-weight: 500;
  width: 70%;
  color: #679186;
  cursor: pointer;

  @include mobile {
    margin: 20px 0px;
    width: 100%;
  }
}

.c-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  font-weight: 400;
}

.c-desc {
  width: 100%;
  font-weight: 300;
  color: #264e70;

  @include mobile {
    display: none;
  }
}

form {
  margin-top: 20px;

  @include mobile {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }
}

input {
  padding-left: 10px;
  margin: 10px 0px;
  border: none;
  border-bottom: 1px solid $mainColor;
  font-size: 14px;
  color: #264e70;
  background-color: #fdebd3;

  @include mobile {
    width: 75%;
    height: 40px;
    margin: 5px;
    margin-left: 0;
  }
}

button {
  border: none;
  padding: 15px;
  background-color: $mainColor;
  color: white;
  font-weight: 500;
  cursor: pointer;

  @include mobile {
    margin-top: 10px;
  }
}

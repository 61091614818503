@import "../../global.scss";

.works {
  background-color: #95adbe;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    @include laptop {
      height: 30px;
    }

    @include mobile {
      height: 20px;
    }

    &.left {
      left: 2%;
      transform: rotateY(180deg);

      @include laptop {
        left: 3%;
      }

      @include mobile {
        left: 1%;
      }
    }

    &.right {
      right: 2%;

      @include laptop {
        right: 3%;
      }

      @include mobile {
        right: 1%;
      }
    }
  }

  .slider {
    height: 85vh;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 85%;
        height: 90%;
        background-color: #fdebd3;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          margin: 15px 0;
        }

        .left {
          flex: 5;
          height: 95%;
          padding-left: 25px;
          padding-right: 20px;

          align-items: center;

          @include mobile {
            display: none;
          }

          .swiper {
            width: 70%;
            height: 100%;

            .swiper-slide {
              margin: 20px;

              text-align: center;
              font-size: 18px;
              background: #fdebd3;

              /* Center slide text vertically */
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
            }

            .swiper-slide img {
              display: block;
              width: 70%;
              height: 70%;
              object-fit: cover;
            }

            .swiper-slide {
              width: 80%;
            }

            .swiper-slide:nth-child(2n) {
              width: 60%;
            }

            .swiper-slide:nth-child(3n) {
              width: 40%;
            }
          }
        }
      }
    }

    .right {
      position: relative;
      flex: 6;
      height: 100%;
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .link-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .imgContainer {
        justify-content: center;
        display: flex;
        margin-bottom: 10px;

      

        &::-webkit-scrollbar {
          display: none;
        }

        img {
          float: right;
          height: 40%;
          max-height: 120px;
          position: relative;
          border-radius: 15%;
        }
      }
      h2 {
        text-align: start;
        font-size: 20px;
        color: $mainColor;
        margin-bottom: 20px;
        &::-webkit-scrollbar {
          display: none;
        }

        @include mobile {
          font-size: 13px;
          margin-bottom: 10px;
          text-align: center;
        }
      }
      p {
        
        font-size: 14px;
        font-weight: 500;
        color: #486662;
        width: 80%;

        @include mobile {
          text-align: center;
          display: none;
        }
      }
      .list {
        width: 80%;
        margin: 20px 0px;
        list-style: inside;
        font-size: 14px;
        font-weight: 500;
        color: #486662;
      }

      .c-info-link {
        
        display: flex;
        align-items: flex-start;
        margin: 20px 0px;
        font-weight: 500;
        width: 70%;
        color: #679186;
        cursor: pointer;

        @include mobile {
          justify-content: center;
          margin: 5px 0px;
          width: 100%;
        }
      }
    }
  }
}

a {
  position: absolute;
  bottom: 10px;
  left: 50%;

  @include mobile {
    left: 48%;
  }

  img {
    width: 40px;
    animation: arrowBlink 2s infinite;

    @include mobile {
      display: none;
    }
  }

  @keyframes arrowBlink {
    100% {
      opacity: 0;
    }
  }
}

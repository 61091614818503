@import "../../global.scss";

.p {
  background-color: #fdebd3;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h1 {
    margin-top: 40px;
    font-size: 50px;
    color: $mainColor;

    @include mobile {
      margin-top: 15px;
      font-size: 20px;
    }
  }

  ul {
    margin: 20px;
    padding: 0;
    list-style: none;
    display: flex;
    color: #679186;
    flex-wrap: wrap;
    justify-content: center;

    @include mobile {
      margin: 10px;
    }

    .portfolioList {
      font-weight: 500;
      font-size: 20px;
      margin-left: 10px;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  .container {
    width: 70%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      margin-top: 5px;
      width: 100%;
    }

    .item {
      width: 250px;
      height: 175px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fdebd3;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;

      &::-webkit-scrollbar {
        display: none;
      }

      @include mobile {
        width: 130px;
        height: 100px;
        margin: 10px 10px;
      }

      h3 {
        margin: 10px;
        position: absolute;
        font-size: 20px;
        text-align: center;
        color: #fdebd3;

        @include mobile {
          font-size: 13px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;

        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }

  a {
    position: absolute;
    bottom: 10px;
    left: 50%;

    @include mobile {
      left: 48%;
    }

    img {
      width: 40px;
      animation: arrowBlink 2s infinite;

      @include mobile {
        display: none;
      }
    }

    @keyframes arrowBlink {
      100% {
        opacity: 0;
      }
    }
  }
}

$mainColor: #264e70;

$medium-width: 1024px;

$small-width: 768px;

@mixin laptop {
  @media (max-width: #{$medium-width}) and (min-width: #{$small-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small-width}) {
    @content;
  }
}

@import "../../../global.scss";

.formInput {
  display: flex;
  flex-direction: column;
  width: 45vh;
  margin: 10px 0px;
}
.input {
  padding: 15px;
  background-color: #fdebd3;
  margin: 20px 0px;
  border-radius: 5px;
  border: 1px solid gray;
  &:invalid[focused="true"] {
    border: 1px solid red;
    & ~ span {
      display: block;
    }
  }
}
